// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-evaluation-whanau-greerton-js": () => import("/opt/build/repo/src/pages/evaluation/whanau/greerton.js" /* webpackChunkName: "component---src-pages-evaluation-whanau-greerton-js" */),
  "component---src-pages-evaluation-whanau-welcome-bay-js": () => import("/opt/build/repo/src/pages/evaluation/whanau/welcome-bay.js" /* webpackChunkName: "component---src-pages-evaluation-whanau-welcome-bay-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-referral-mental-health-additional-js": () => import("/opt/build/repo/src/pages/referral/mental-health/Additional.js" /* webpackChunkName: "component---src-pages-referral-mental-health-additional-js" */),
  "component---src-pages-referral-mental-health-client-details-js": () => import("/opt/build/repo/src/pages/referral/mental-health/ClientDetails.js" /* webpackChunkName: "component---src-pages-referral-mental-health-client-details-js" */),
  "component---src-pages-referral-mental-health-important-js": () => import("/opt/build/repo/src/pages/referral/mental-health/Important.js" /* webpackChunkName: "component---src-pages-referral-mental-health-important-js" */),
  "component---src-pages-referral-mental-health-index-js": () => import("/opt/build/repo/src/pages/referral/mental-health/index.js" /* webpackChunkName: "component---src-pages-referral-mental-health-index-js" */),
  "component---src-pages-referral-mental-health-medical-details-js": () => import("/opt/build/repo/src/pages/referral/mental-health/MedicalDetails.js" /* webpackChunkName: "component---src-pages-referral-mental-health-medical-details-js" */),
  "component---src-pages-referral-mental-health-referrer-details-js": () => import("/opt/build/repo/src/pages/referral/mental-health/ReferrerDetails.js" /* webpackChunkName: "component---src-pages-referral-mental-health-referrer-details-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

